<template>
  <div>
    <ac-module-heading> Reset Password </ac-module-heading>
    <v-card>
      <v-card-title> Compliance Manager </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <ac-input label="Email" outlined></ac-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <ac-button title="Submit"></ac-button>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import AcInput from '@/components/AcInput.vue'
import AcButton from '@/components/AcButton.vue'
export default {
  name: 'ResetPassword',
  components: {
    AcInput,
    AcButton,
  },
}
</script>

<style lang="scss" scoped></style>
