import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ac-module-heading',[_vm._v(" Reset Password ")]),_c(VCard,[_c(VCardTitle,[_vm._v(" Compliance Manager ")]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c('ac-input',{attrs:{"label":"Email","outlined":""}})],1)],1)],1),_c(VCardActions,[_c('ac-button',{attrs:{"title":"Submit"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }